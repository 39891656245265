<template>
  <div>
    <v-row>
      <v-col>
        <v-row dense>
          <iframe
            width="100%"
            height="400"
            src="https://time.graphics/embed?v=1&id=d45ec6e3985321906f96824eeb865823"
            frameborder="0"
            allowfullscreen
          >
          </iframe>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <h1>The World</h1>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p>
                  An incomplete seedship orbits outside of the massive debris
                  fields shielding a depleted Earth. Arcologies founded by
                  competing mega-corporate interests fund The Mission Council;
                  dedicated to the cause of completing the seedship before
                  humanity is extinguished. But the project has stalled, without
                  meaningful progress in generations.
                </p>
                <p>
                  The seedship houses 1.8 million citizens aboard a 13 kilometer
                  rotating ring; the largest remaining bastion of humanity, and
                  it serves as a trade hub for the stations, belters, and
                  captains that call space their home. Civility is maintained by
                  private security companies that bid and keep peace by
                  contracts provided by The Mission Council. Syndicate families
                  – estranged from Earth – now play the seedship for power
                  having lost their once powerful seat at the Mission Council’s
                  table.
                </p>
                <p>
                  It’s post-apocalyptic cyberpunk. Everything has been depleted
                  and it doesn't look like our last hope will be enough to make
                  it out of the solar system. The power structures of old have
                  decayed, and an individual’s reputation is everything.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <h1>Game Features</h1>
          </v-col>
          <v-col v-for="feature in features" :key="feature.title" cols="4">
            <v-card elevation="2">
              <v-card-title class="text-h5">{{ feature.title }}</v-card-title>
              <v-card-text>{{ feature.content }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-card rounded="lg" align="center" justify="space-around">
          <v-card-text>
            <a href="http://play.torchship.org/player-client/">
              <v-btn color="primary" large width="80%"> Play Now </v-btn>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "About",
  data: () => ({
    features: [
      {
        title: "Roleplay First",
        content:
          "There are a wide variety of text-based gaming options available. Some focus on hack-and-slash mechanics, and some have no mechanics at all. Torchship is a roleplay-intensive game with many coded systems all designed to support players in roleplay and add impact to their choices.",
      },
      {
        title: "Classless & Levelless",
        content:
          "Progression in Torchship happens slowly over time. Earn experience daily and use skills to increase them. Retrain over time and discover new skills in game.",
      },
      {
        title: "Play Anywhere",
        content:
          "Torchship comes with an enhanced web client that can be played straight from the browser. Full ANSI support, command hints, command echoing, and logging are all built-in features.",
      },
      {
        title: "Extreme Customization",
        content:
          "Find out what it means to be transhuman. Replace your limbs with mechanical ones. Modify your cyberware. Your body is yours to change and improve.",
      },
      {
        title: "Narrative Chargen",
        content:
          "Play with our immersive character generation system which includes a Traveller-style lifepath system. Start with friends, enemies, money, or even a cyberlimb!",
      },
      {
        title: "Rep is Everything",
        content:
          "Gain and lose reputation with Torchship's rich and diverse factions and groups as you play your character. Earn access to restricted equipment and areas, make friends and enemies. Reputation is everything aboard Torchship.",
      },
    ],
  }),
};
</script>
